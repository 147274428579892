import React, {useState}  from 'react';
import './App.css';

function App() {
  const [isShown, setIsShown] = useState(false);
  return (
    <div className="App">
        <div onMouseEnter={()=>setIsShown(true)} onMouseLeave={()=>setIsShown(false)}
             >
            {isShown ? 
            (<a href="mailto:noscogreen@gmail.com" className="Contact">contact</a>)
            : 
            (<div>Les investissements d'aujourd'hui sont les émissions de demain</div>)}
        </div>
        
      </div>
  );
}

export default App;
